import React, { useCallback, useEffect, useState } from 'react';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import {
    setCoreIframeHtml,
    setPreviousAuthStateForPayments,
    useAppStatePaymentSelector,
} from './paymentSlice';
import styles from './Payment.module.scss';
import { useTranslation } from 'react-i18next';
import { PanelRow } from '../../components/panel/PanelRow';
import { Panel } from '../../components/panel/Panel';
import { useDispatch } from 'react-redux';
import { getCoreIframeURL } from './paymentActionCreators';
import { useQuery } from '../../hooks/useQuery';
import { useAuthSelector } from '../auth/authSlice';
import { CoreBillPayForm } from '../../components/coreIframe/CoreBillPayForm';
import { Spinner } from '../../components/spinner/Spinner';
import { Text } from '../../components/text/Text';
import { Button } from '../../components/button/Button';
import { useHistory } from 'react-router-dom';
import { config } from '../../config';

interface PaymentSuccessDetails {
    amount: number;
    paymentType: string;
    authNetTransId: string;
    navilineRoutingId: string;
}

const PaymentConfirmation = ({
    successDetails,
}: {
    successDetails: PaymentSuccessDetails;
}) => {
    const { amount, paymentType, authNetTransId, navilineRoutingId } =
        successDetails;
    const { t } = useTranslation();
    return (
        <div className={styles.paymentConfirmationContainer}>
            <h3>
                <span className={`far fa-check-circle ${styles.successIcon}`} />
                {t('Payment.ThankYouForPayment')}
            </h3>
            <p className={styles.confirmationDetail}>
                <b>{t('Payment.PaymentAmount')}: </b>${amount.toFixed(2)}
            </p>
            <p className={styles.confirmationDetail}>
                <b>{t('Payment.PaymentType')}: </b>
                {paymentType}
            </p>
            <p className={styles.confirmationDetail}>
                <b>{t('Payment.TransactionId')}: </b>
                {authNetTransId}
            </p>
            <p className={styles.confirmationDetail}>
                <b>{t('Payment.RoutingId')}: </b>
                {navilineRoutingId}
            </p>
        </div>
    );
};

// enum PaymentStatus {
//     NONE = 'NONE',
//     LOADING = 'LOADING',
//     SUCCESS = 'SUCCESS',
// }

export enum PaymentEventCallType {
    OVERLAY_OFF = 'OVERLAY-OFF',
    OVERLAY_ON = 'OVERLAY-ON',
    PAYMENT_SUBMIT = 'PAYMENTPROCESS-SUBMIT',
    PAYMENT_SUCCESS = 'PAYMENTPROCESS-SUCCESS',
    PAYMENTPROCESS_ERROR = 'PAYMENTPROCESS-ERROR',
}

export const CorePayment = () => {
    const dispatch = useDispatch();
    // const history = useHistory();
    const { t } = useTranslation();
    const [apiError] = useState('');
    const [successDetails] = useState<PaymentSuccessDetails>();
    // const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.NONE);
    const querystring = useQuery();
    const accountNumber = querystring.get('accountNumber');
    const accountType = querystring.get('accountType')
        ? querystring.get('accountType')
        : 'UTILITY';
    const amount = querystring.get('amount')
        ? parseFloat(querystring.get('amount')!)
        : 0;
    const [amountSet, setAmount] = useState<number | null>(null);
    const [paymentAmountEntered, setPaymentAmountEntered] = useState<
        number | null
    >(amount && amount >= 0 ? amount : 0);
    const penaltyAmount = querystring.get('penaltyAmount')
        ? parseFloat(querystring.get('penaltyAmount')!)
        : 0;
    const { isAuthenticated } = useAuthSelector((s) => s);
    const { coreIframeHtml } = useAppStatePaymentSelector((state) => state);
    const [paymentAmount] = useState<number | null>(
        Math.max(amount + penaltyAmount, 1),
    );
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const history = useHistory();
    const topOfIframeRef = React.useRef<HTMLDivElement>(null);

    const scrollToTopOfIFrame = useCallback(
        (diff) => {
            // find the div

            const iframe = document.getElementById('billPayIframe');
            if (iframe) {
                const iframeTop = topOfIframeRef.current?.offsetTop! - diff;

                setTimeout(() => {
                    window.scrollTo({
                        top: iframeTop,
                        behavior: 'smooth',
                    });
                }, 250);
                if (firstLoad) {
                    setFirstLoad(false);
                }
            }
        },
        [firstLoad],
    );

    const processEvent = useCallback(
        (event) => {
            const data = event?.data;
            const callType = data?.calltype;
            const contentHeight = data?.contentHeight;

            if (contentHeight) {
                const iframe = document.getElementById('billPayIframe');
                if (iframe) {
                    console.log('setting height to', contentHeight);
                    iframe.style.height = contentHeight + 'px';
                }
            }

            // Check the window width/height to appropriately size the iframe when there is an error.
            // Make sure to account for height changes with "save card for future payments" option opening.
            if (
                callType === PaymentEventCallType.PAYMENTPROCESS_ERROR &&
                event.origin === config.core.iframeOriginUrl
            ) {
                const iframe = document.getElementById('billPayIframe');
                if (iframe) {
                    scrollToTopOfIFrame(110);
                    iframe.style.height = '600px';
                    iframe.setAttribute('scrolling', 'yes');
                }
            }

            if (callType === PaymentEventCallType.OVERLAY_ON) {
                setLoading(true);
            }

            if (callType === PaymentEventCallType.OVERLAY_OFF) {
                setLoading(false);
            }

            if (callType === PaymentEventCallType.PAYMENT_SUCCESS) {
                setPaymentSuccess(true);
                const iframe = document.getElementById('billPayIframe');
                if (iframe) {
                    // Account for different screen sizes when getting the receipt
                    scrollToTopOfIFrame(110);
                    iframe.setAttribute('scrolling', 'yes');
                }
            }
        },
        [scrollToTopOfIFrame],
    );

    useEffect(() => {
        // set initial user auth state
        dispatch(setPreviousAuthStateForPayments(isAuthenticated));

        // clear the iframe
        dispatch(setCoreIframeHtml(''));
    }, [dispatch, isAuthenticated]);

    useEffect(() => {
        if (penaltyAmount || accountType === 'PERMIT') {
            setPaymentAmountEntered(
                amount + penaltyAmount >= 0 ? amount + penaltyAmount : 0,
            );
            setAmount(amount + penaltyAmount);
        }

        if (!coreIframeHtml && amountSet !== null && !loading) {
            setLoading(true);
            dispatch(
                getCoreIframeURL(
                    'SINGLE',
                    accountNumber,
                    amountSet?.toFixed(2),
                    accountType ? accountType! : undefined,
                ),
            );
            window.addEventListener('message', processEvent, false);
        }

        if (!amountSet && coreIframeHtml) {
            // clear the iframe
        }

        // check if coreIframeHtml is empty string or null
        if (coreIframeHtml && firstLoad) {
            scrollToTopOfIFrame(125);

            // If there is an iframe error modal, scroll to the top to show it
            const iframe = document.getElementById(
                'billPayIframe',
            ) as HTMLIFrameElement;
            if (iframe) {
                const saveChangesButton =
                    iframe.contentDocument?.getElementById(
                        'eGov_functionComplete',
                    );
                if (saveChangesButton) {
                    saveChangesButton.addEventListener('click', () =>
                        scrollToTopOfIFrame(0),
                    );
                }
            }
        }
    }, [
        dispatch,
        processEvent,
        accountNumber,
        paymentAmount,
        accountType,
        coreIframeHtml,
        amountSet,
        penaltyAmount,
        amount,
        loading,
        firstLoad,
        scrollToTopOfIFrame,
    ]);

    return (
        <PageContainer
            style={isAuthenticated ? {} : { maxWidth: 700, width: '100%' }}
        >
            <Spinner isLoading={loading} fullPage />
            <div className="headerContainer">
                <h2>{t('Payment.Payment')}</h2>
            </div>

            {isAuthenticated ? (
                <PanelRow>
                    <Panel>
                        {successDetails ? (
                            <PaymentConfirmation
                                successDetails={successDetails}
                            />
                        ) : (
                            <>
                                {accountType && (
                                    <p>
                                        {t('Payment.Account')}: {accountType}
                                    </p>
                                )}
                                {penaltyAmount ? (
                                    <>
                                        {/* If the user is applying for permits, check if there is a penalty amount */}
                                        <p>
                                            {t('Payment.PermitDue')}: $
                                            {amount.toFixed(2)}
                                        </p>
                                        <p style={{ color: '#BF0000' }}>
                                            {t('Payment.PenaltyDue')}: $
                                            {penaltyAmount.toFixed(2)}
                                        </p>
                                        <p>
                                            {t('Payment.TotalDue')}: $
                                            {(amount + penaltyAmount).toFixed(
                                                2,
                                            )}
                                        </p>
                                    </>
                                ) : (
                                    <p>
                                        {t('Payment.TotalDue')}: $
                                        {amount.toFixed(2)}
                                    </p>
                                )}
                            </>
                        )}
                    </Panel>
                </PanelRow>
            ) : null}

            {!penaltyAmount && accountType !== 'PERMIT' && (
                <PanelRow>
                    <Panel>
                        <div>
                            <p>
                                <strong>Enter your payment amount:</strong>
                            </p>

                            <div>
                                <span className={styles.slightMarginRight}>
                                    Payment: $
                                </span>
                                <Text
                                    value={paymentAmountEntered!}
                                    fluid={false}
                                    step="0.01"
                                    min={1}
                                    type={'number'}
                                    disabled={amountSet !== null}
                                    onChange={(event: any, data: any) =>
                                        setPaymentAmountEntered(
                                            parseFloat(data.value),
                                        )
                                    }
                                />
                            </div>

                            <Button
                                onClick={() => {
                                    if (paymentAmountEntered) {
                                        setAmount(paymentAmountEntered);
                                    }
                                }}
                                disabled={
                                    (paymentAmountEntered
                                        ? paymentAmountEntered
                                        : 0) <= 0 || amountSet !== null
                                }
                                color={'blue'}
                            >
                                {t('Util.Next')}
                            </Button>
                        </div>
                    </Panel>
                </PanelRow>
            )}

            {!successDetails && coreIframeHtml && amountSet && (
                <PanelRow>
                    <Panel>
                        <div className="topOfIframe" ref={topOfIframeRef} />
                        <CoreBillPayForm html={coreIframeHtml} />
                        {apiError !== '' && (
                            <p className={styles.errorMessage}>{apiError}</p>
                        )}
                        {paymentSuccess && (
                            <div
                                className={styles.paymentConfirmationContainer}
                            >
                                <Button
                                    onClick={() => {
                                        history.push('/dashboard');
                                    }}
                                    color={'blue'}
                                >
                                    {t('Util.Finish')}
                                </Button>
                            </div>
                        )}
                    </Panel>
                </PanelRow>
            )}
        </PageContainer>
    );
};
