import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import Parser from 'rss-parser';
import Slider from 'react-slick';
import moment from 'moment';

import { config } from '../../config';
import useWindowDimensions from '../../store/utilities/useWindowDimensions';
import { useAuthSelector } from '../../features/auth/authSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';

import styles from './News.module.scss';

type NewsItem = {
    title: string;
    link: string;
    pubDate: string;
    contentSnippet: string;
    enclosure: {
        url: string;
    };
};

export const News = () => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuthSelector((state) => state);
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const [latestNews, setLatestNews] = useState<NewsItem[]>([]);

    const getNewsItemsSorted = (newsItems: NewsItem[]) => {
        const sortedNewsItems = newsItems.sort(
            (newsItem1: NewsItem, newsItem2: NewsItem) => {
                if (moment(newsItem1.pubDate).isAfter(newsItem2.pubDate)) {
                    return -1;
                } else if (
                    moment(newsItem1.pubDate).isBefore(newsItem2.pubDate)
                ) {
                    return 1;
                } else {
                    return 0;
                }
            },
        );

        return sortedNewsItems;
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const parser = new Parser<any, NewsItem>();
                const response = await parser.parseURL(
                    `${config.apiUrl}/feeds/news`,
                );

                // If there are less than 3 items, put in empty placeholders otherwise the slider will display elements vertically
                if (response.items.length < 3) {
                    let numOfPlaceholdersToAdd = 3 - response.items.length;
                    for (let i = 0; i < numOfPlaceholdersToAdd; i++) {
                        response.items.push({
                            title: '',
                            link: '',
                            pubDate: '',
                            contentSnippet: '',
                            enclosure: {
                                url: '',
                            },
                        });
                    }
                }

                const sortedNewsItems = getNewsItemsSorted(response.items);
                setLatestNews(sortedNewsItems);
            } catch {
                dispatch(
                    openMessageModal(null, 'error') as unknown as AnyAction,
                );
            }
        };

        fetchNews();
    }, [dispatch]);

    const SliderArrow = (props: any | { arrowDirection: string }) => {
        const { className, style, onClick, arrowDirection } = props;

        const sliderArrowContainerStyle: CSSProperties = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '35px',
            height: '35px',
            borderRadius: '35px',
            border: '2px solid black',
        };

        let sliderArrowStyle: CSSProperties = {
            width: 0,
            height: 0,
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
        };

        if (arrowDirection === 'left') {
            sliderArrowStyle['borderRight'] = '10px solid black';
            sliderArrowStyle['marginRight'] = '3px';
        } else if (arrowDirection === 'right') {
            sliderArrowStyle['borderLeft'] = '10px solid black';
            sliderArrowStyle['marginLeft'] = '3px';
        }

        return (
            <div
                className={className}
                style={{ ...style, ...sliderArrowContainerStyle }}
                onClick={onClick}
            >
                <div style={{ ...sliderArrowStyle }} />
            </div>
        );
    };

    const getSlidesToShow = () => {
        if (width) {
            if ((isAuthenticated || !isAuthenticated) && width < 750) {
                return 1;
            } else if ((isAuthenticated || !isAuthenticated) && width < 1400) {
                return 2;
            } else if (isAuthenticated) {
                return 2;
            } else if (!isAuthenticated) {
                return 3;
            }
        } else {
            return 1;
        }
    };

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        prevArrow: <SliderArrow arrowDirection="left" />,
        nextArrow: <SliderArrow arrowDirection="right" />,
    };

    const NewsHeader = () => {
        return (
            <div className={styles.newsTitleContainer}>
                <h2>{t('Home.LatestNews')}</h2>
            </div>
        );
    };

    const NewsSlider = () => {
        if (latestNews.length) {
            return (
                <Slider {...sliderSettings}>
                    {latestNews.map((newsItem: NewsItem, index) => (
                        <div key={index}>
                            <div className={styles.newsItemContainer}>
                                <div className={styles.newsItemSubContainer}>
                                    <NewsImage newsItem={newsItem} />
                                    <NewsPublishDate newsItem={newsItem} />
                                    <NewsTitle newsItem={newsItem} />
                                    <NewsContentSnippet newsItem={newsItem} />
                                    <NewsLink newsItem={newsItem} />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            );
        } else {
            return null;
        }
    };

    const NewsImage = (props: { newsItem: NewsItem }) => {
        const { newsItem } = props;

        if (!newsItem?.enclosure?.url) {
            return <></>;
        }

        return (
            <div className={styles.newsItemImageContainer}>
                <a
                    href={newsItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className={styles.newsItemImage}
                        src={newsItem.enclosure.url}
                        alt={newsItem.title}
                    />
                </a>
            </div>
        );
    };

    const NewsPublishDate = (props: { newsItem: NewsItem }) => {
        const { newsItem } = props;

        return (
            <div className={styles.newsItemPublishDateContainer}>
                <div className={styles.newsItemPublishDateTextContainer}>
                    <p className={styles.newsItemPublishDateText}>
                        {moment(
                            newsItem.pubDate,
                            'ddd, DD MMM YYYY HH:mm:ss Z',
                        ).format('MMMM DD, YYYY')}
                    </p>
                </div>
            </div>
        );
    };

    const NewsTitle = (props: { newsItem: NewsItem }) => {
        const { newsItem } = props;

        return (
            <div className={styles.newsItemTitleContainer}>
                <a
                    className={styles.newsItemTitleText}
                    href={newsItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {newsItem.title}
                </a>
            </div>
        );
    };

    const NewsContentSnippet = (props: { newsItem: NewsItem }) => {
        const { newsItem } = props;

        return (
            <div className={styles.newsItemContentSnippetContainer}>
                <p className={styles.newsItemContentSnippetText}>
                    {newsItem.contentSnippet}
                </p>
            </div>
        );
    };

    const NewsLink = (props: { newsItem: NewsItem }) => {
        const { newsItem } = props;

        return (
            <div className={styles.newsItemLinkContainer}>
                <a
                    className={styles.newsItemLinkText}
                    href={newsItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('Home.LatestNewsItemRedirect')}
                </a>
            </div>
        );
    };

    return (
        <div
            className={
                isAuthenticated
                    ? styles.newsParentDashboardContainer
                    : styles.newsParentHomeContainer
            }
        >
            <NewsHeader />
            <NewsSlider />
        </div>
    );
};
