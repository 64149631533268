import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/button/Button';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { Panel } from '../../../components/panel/Panel';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Spinner } from '../../../components/spinner/Spinner';
import { Text } from '../../../components/text/Text';
import { salesforceService } from '../../../services/salesforceService';
import styles from './KnowledgeDetails.module.scss';

export function KnowledgeBaseDetails({ location }: { location: Location }) {
    const history = useHistory();
    const { id } = useParams() as { id: string };

    const [searchTerm, setSearchTerm] = useState('');
    const [article, setArticle] = useState<any>();
    const [fetched, setFetched] = useState(false);

    const search = () => {
        history.push({
            pathname: '/knowledgebase',
            search: `?term=${encodeURIComponent(searchTerm)}`,
        });
    };

    const onTextChange = (_e: any, data: { value: any }) => {
        setSearchTerm(data.value);
    };

    const onKeyDown = (e: { key: string }) => {
        if (e.key === 'Enter') {
            search();
        }
    };

    useEffect(() => {
        (async () => {
            setArticle(await salesforceService.getKnowledgeArticle(id));
            setFetched(true);
        })();
    }, [id]);

    return (
        <>
            <PageContainer className={styles.unauthenticatedView}>
                <PanelRow>
                    <Panel size={2} style={{ alignSelf: 'flex-start' }}>
                        <h2>Search Help Articles</h2>
                        <div className={styles.termWrapper}>
                            <Text
                                onKeyDown={onKeyDown}
                                value={searchTerm}
                                onChange={onTextChange}
                            />
                            <Button onClick={search}>Search</Button>
                        </div>
                    </Panel>
                </PanelRow>
                <PanelRow>
                    <Panel>
                        <div className={styles.headerContainer}>
                            {fetched && article && (
                                <h1 className={styles.heading}>
                                    {article.title}
                                </h1>
                            )}
                            <Spinner isLoading={!fetched} />
                            {fetched && article && <div>{article.summary}</div>}
                        </div>
                    </Panel>
                </PanelRow>
                <PanelRow>
                    <Panel>
                        {article &&
                            article.layoutItems
                                .filter((x: any) => x.name !== 'Chat_Answer')
                                .map((x: any, index: number) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className={styles.layoutItem}>
                                                <h2>{x.label}</h2>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: x.value,
                                                    }}
                                                ></div>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                    </Panel>
                </PanelRow>
            </PageContainer>
        </>
    );
}
